<template>
	<div
		id="popupLayer"
		class="com_layer_normal layer_accounting_payment"
		style="position: absolute; left: 18%; top: 10%; width: 1200px !improtant"
	>
		<br />
		<div class="lay_header">
			<h1>{{ item.addonChannelModel.name }} 유료전환</h1>
			<a class="btn_close" title="닫기"><span class="ic_set ic_close" v-on:click="closeLayer"></span></a>
		</div>
		<!--content-->
		<div class="lay_body">
			<div class="com_form_large">
				<!--부가서비스 : 경리회계선택-->
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit">
							<span class="txt_medium">부가 서비스</span>
						</label>
						<div class="form_data">
							<div class="com_card_normal card_addon active">
								<div class="wrap_row_card">
									<!--<div class="com_ck bk">
										<label
											><input type="checkbox" value="true"/><span
												class="checkmark"
											></span
										></label>
									</div>-->
									<span class="ic_svc ic_accounting"></span>
									<span class="tit">{{ item.addonChannelModel.name }}</span>
									<span class="com_help">
										<span class="ic_set ic_info">
											<span class="tool_tip">
												총 100명 이상 사용 시,<br />
												사용자의 구간에 따라 최소 20% ~ 최대 60% 까지 할인율이 적용됩니다 <br />
												(장기 선납 할인과 중복 적용)
												<i class="tail_left"></i
											></span>
										</span>
									</span>
								</div>
								<div class="wrap_meta_addon">
									<table class="com_tb_metadata">
										<tbody>
											<tr>
												<td class="tit"><span class="txt">설치비</span></td>
												<td class="data">
													<span class="txt"
														>{{ item.addonChannelModel.membershipFeePolicyModel.price }}원 / 최초 1회</span
													>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">멤버 (1명)</span>
												</td>
												<td class="data">
													<span class="txt">{{ item.addonChannelModel.addUserIntervalPolicyModel.price }}원 / 월</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">총 멤버 수</span>
												</td>
												<td class="data">
													<span class="txt">{{ item.totalUserCount }}명</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--옵션선택-->
				<div class="wrap_row">
					<!--@옵션 - 2-->
					<div class="row">
						<label class="form_tit">
							<span class="txt_medium">옵션</span>
						</label>
						<div class="form_data">
							<!--@경리회계-->
							<div
								class="com_card_normal card_addon active"
								v-for="optionItem in item.usingChannelItemModels"
								:key="optionItem.code"
							>
								<div class="wrap_row_card">
									<!--<div class="com_ck bk">
										<label
											><input type="checkbox" checked="checked"/><span
												class="checkmark"
											></span
										></label>
									</div>-->
									<span class="ic_svc ic_accounting"></span>
									<span class="tit">{{ optionItem.channelItemModel.name }}</span>
									<span class="com_help">
										<span class="ic_set ic_info">
											<span class="tool_tip">
												총 100명 이상 사용 시,<br />
												사용자의 구간에 따라 최소 20% ~ 최대 60% 까지 할인율이 적용됩니다 <br />
												(장기 선납 할인과 중복 적용)
												<i class="tail_left"></i
											></span>
										</span>
									</span>
								</div>
								<div class="wrap_meta_addon">
									<table class="com_tb_metadata">
										<tbody>
											<tr>
												<td class="tit"><span class="txt">개당</span></td>
												<td class="data">
													<span class="txt">{{ optionItem.price }}원 / 월</span>
												</td>
											</tr>
											<tr>
												<td class="tit">
													<span class="txt">총 개수</span>
												</td>
												<td class="data">
													<span class="txt">{{ optionItem.usingCount }}개</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--결제금액-->
				<order-info></order-info>
				<template v-if="usingMembership.autoExtension">
					<div>
						<span class="txt"
							><a style="color: red">{{ getNextPaymentDate(item.startDate) }}</a>
							기존 자동결제 금액에 상기 유료전환금액
							<a style="color: red">{{ this.$store.getters.getSummuryPrices.finalPayPrice }}원(VAT포함)</a>이 합산되어
							결제될 예정입니다.</span
						>
					</div>
					<div class="lay_bottom">
						<div class="wrap_function">
							<button class="major" v-on:click="saveAutoExtension">유료전환 신청</button>
							<button class="minor" v-on:click="closeLayer">취소</button>
						</div>
					</div>
				</template>
				<!--@--결제방법-->
				<template v-if="!usingMembership.autoExtension">
					<pay-method :saveFlag="saveFlag" v-on:setPayMethodInfo="setPayMethodInfo"></pay-method>
					<!--//content end-->
					<div class="lay_bottom">
						<div class="wrap_function">
							<button class="major" v-on:click="save">결제하기</button>
							<button class="minor" v-on:click="closeLayer">취소</button>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div
			v-if="enablePopup"
			style="
				position: fixed;
				z-index: 101;
				top: 300px;
				left: 800px;
				background-color: white;
				width: 700px;
				height: 786px;
			"
		>
			<div>
				<span class="tit">결제하기</span>
				<i class="ic_set ic_del" title="닫기" @click="closePopup()" style="cursor: pointer"></i>
			</div>
			<iframe :src="paymentUrl" height="700px" width="700px"></iframe>
		</div>
	</div>
</template>

<script>
import OrderInfo from '@/components/apply/resultInfo/OrderInfo.vue';
import PayMethod from '@/components/payment/method/PayMethod.vue';
export default {
	components: {
		OrderInfo,
		PayMethod,
	},
	data() {
		return {
			newStartDate: '', // 신규 시작 일자
			newEndDate: '', // 신규 종료 일자
			payPeriod: {}, // 잔여 일자(결제 해야하는)
			stepBtnInfo: {
				/*next: '결제하기',*/
				next: '결제하기',
				prevLink: '/apply/service/step1',
			},
			paymentUrl: '/kiwoom/popup',
			enablePopup: false,
			saveFlag: false,
			payMethodInfo: {},
		};
	},
	props: {
		usingMembership: {
			type: Object,
		},
		item: {
			type: Object,
		},
		endDate: {
			type: String,
		},
	},
	async created() {
		await this.$store.commit('clearApplyServiceInfo');
		let addonItem = this.item.addonChannelModel;
		const newStartDate = new Date(this.item.endDate);
		newStartDate.setDate(newStartDate.getDate() + 1);
		const year = new String(newStartDate.getFullYear());
		let month = new String(newStartDate.getMonth() + 1);
		let day = new String(newStartDate.getDate());
		if (month.length == 1) {
			month = '0' + month;
		}
		if (day.length == 1) {
			day = '0' + day;
		}
		const startDateStr = year + '-' + month + '-' + day;
		this.newStartDate = startDateStr; // 신규 사용 시작일 = 존 사용 종료일 + 1

		this.usePeriod = this.$store.getters.getUsePeriod;
		if (this.usingMembership.autoExtension) {
			this.remainDay = { remainMonth: 1, remainDay: 0 };
			const newEndDate = newStartDate;
			newEndDate.setMonth(newEndDate.getMonth() + 1); // 시작일 + 1달

			const year = new String(newEndDate.getFullYear());
			let month = new String(newEndDate.getMonth() + 1);
			let day = new String(newEndDate.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			const endDateStr = year + '-' + month + '-' + day;
			this.newEndDate = endDateStr; // 기존 종료일
		} else {
			// api 호출

			const { data } = await this.$store.dispatch('GETREMAINDAYS', {
				membershipId: this.usingMembership.id,
				startDate: startDateStr,
			});
			this.remainDay = data;
			this.newEndDate = this.endDate; // 기존 종료일
		}
		console.log(this.remainDay);
		this.$store.commit('setUsePeriod', this.remainDay);
		addonItem = await this.setBaseInfo(addonItem, this.remainDay, this.item.totalUserCount);

		this.$store.commit('setStartDate', this.newStartDate);
		this.$store.commit('setEndDate', this.newEndDate);
		addonItem = await this.setMembershipFeeDiscountMatrix(addonItem);
		addonItem = await this.setDefaultFeeDiscountMatrix(addonItem);
		addonItem = await this.setAddUserDiscountMatrix(addonItem);
		addonItem = await this.setDiscountSum(addonItem);

		// price Cutting

		addonItem['type'] = 'converting';
		await this.$store.commit('addAddonChannelSelected', addonItem);
		await this.item.usingChannelItemModels.reduce((previous, current) => {
			return previous.then(async () => {
				var optionItemModel = current.channelItemModel;
				optionItemModel = await this.setInfo_ACO(optionItemModel, this.remainDay, parseInt(current.usingCount));
				await this.$store.commit('addAddonOptionSelected', {
					addonCode: addonItem.code,
					data: optionItemModel,
				});
			});
		}, Promise.resolve());
	},
	methods: {
		priceCutting(aprice, stype, n) {
			// 금액, 타입, 절삭금액 단위
			// 원단위처리(R:반올림, C:올림, F:버림)
			var remove_price = 0;
			stype = stype ? stype : 'R';
			remove_price = aprice / n;

			if (stype == 'F') {
				remove_price = Math.floor(remove_price);
			} else if (stype == 'R') {
				remove_price = Math.round(remove_price);
			} else if (stype == 'C') {
				remove_price = Math.ceil(remove_price);
			}

			remove_price = remove_price * n;
			return remove_price;
		},
		async createOrder() {
			try {
				var param = await this.makeOrderData();
				console.log(param);
				const { data } = await this.$store.dispatch('CREATENEWORDERINFO', param);

				let payType = '';
				if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
					payType = 'AUTO_CREDITCARD';
				} else if (this.payMethodInfo.payType == 'CREDITCARD') {
					payType = 'CREDITCARD';
				} else if (this.payMethodInfo.payType == 'DEPOSIT') {
					payType = 'TRANSPORT';
				} else if (this.payMethodInfo.payType == 'ACCOUNTTRANSFER') {
					payType = 'REALTIME_TRANSPORT';
				}

				param.payType = payType;
				data['serviceType'] = param.serviceType;
				await this.$store.dispatch('APPLYNEWORDERINFO', data);
				if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
					// 카드번호 저장
					if (this.payMethodInfo.cardNo.length < 14) {
						alert('카드 번호가 올바르지 않습니다.');
						this.saveFlag = false;
						return;
					} else {
						const customerId = data.customer.id;
						const lastCardNo = this.payMethodInfo.cardNo.substr(12, 4);
						console.log(lastCardNo);
						await this.saveCardInfo(customerId, lastCardNo); // 자동결제 카드 정보 등록
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		async saveCardInfo(customerId, lastCardNo) {
			const cardInfo = {
				customerId: customerId,
				lastCardNumber: lastCardNo,
			};

			const response = await this.$store.dispatch('SAVEAUTOPAYMENTCARDINFO', cardInfo);
		},
		async makeOrderData() {
			const date = new Date();
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			const current = year + '-' + month + '-' + day;
			const startDate = this.newStartDate;
			const endDate = this.$store.getters.getEndDate;
			// 공통
			// 1. customer(고객사정보)

			// 2. baseInfoModel
			const baseInfoModel = {
				orderType: 'ADD',
				orderMonthCount: 0,
				orderDate: this.$utils.currentLocalDateTime(), //오늘
				applyDate: startDate,
				orderEndDate: endDate,
				salesRepresentative: null,
			};
			// 5. serviceType
			const serviceType = this.usingMembership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType;
			// 6. payType
			let payType = '';

			console.log(this.payMethodInfo.payType);
			if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
				payType = 'AUTO_CREDITCARD';
			} else if (this.payMethodInfo.payType == 'CREDITCARD') {
				payType = 'CREDITCARD';
			} else if (this.payMethodInfo.payType == 'DEPOSIT') {
				payType = 'TRANSPORT';
			} else if (this.payMethodInfo.payType == 'ACCOUNTTRANSFER') {
				payType = 'REALTIMETRANSPORT';
			}
			const membershipModel = this.usingMembership;
			// 부가서비스 정보
			// 8. addonChannelOrderInfoModels(AddonChannelOrderInfoModel list)
			const addonItemSelectedList = this.$store.getters.getAddonItemSelected;
			let addonChannelOrderInfoModels = [];
			addonItemSelectedList.forEach((addonItem) => {
				const addonChannelModel = addonItem;
				let addonChannelBaseInfoModel = {
					url: this.item.addonChannelBaseInfoModel.url,
					adminLoginId: this.item.addonChannelBaseInfoModel.adminLoginId,
				};

				// 각 금액(기본료,설치비,사용자추가) detail
				// defaultFeeOrderDetail(기본료 상세)
				const addonDefaultFeeDiscountIdx = addonItem.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'DEFAULT_FEE',
				);
				let addonDefaultFeeDiscountInfoModels = [];
				if (addonDefaultFeeDiscountIdx > -1) {
					addonDefaultFeeDiscountInfoModels.push(addonItem.discountPolicyModels[addonDefaultFeeDiscountIdx]);
				}
				const addonDefaultFeeOrderDetail = {
					channelItemSimpleModel: null,
					channelItemType: 'DEFAULT_FEE',
					itemCode: addonItem.defaultFeePolicyModel.code,
					totalOrderCount: 1,
					existingCount: 0,
					addCount: 1,
					price: addonItem.defaultFeePolicyModel.price, // 단가
					finalPrice: addonItem.defaultFeeDiscountPrice,
					note: '기본료',
					useFulfillment: false,
					discountInfoModels: addonDefaultFeeDiscountInfoModels,
					status: 'USING',
					itemCancelInfoModel: null,
				};

				// membershipFeeOrderDetail(설치비 상세)
				const addonMembershipFeeDiscountIdx = addonItem.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'MEMBERSHIP_FEE',
				);
				let addonMembershipFeeDiscountInfoModels = [];
				if (addonMembershipFeeDiscountIdx > -1) {
					addonMembershipFeeDiscountInfoModels.push(addonItem.discountPolicyModels[addonMembershipFeeDiscountIdx]);
				}
				const addonMembershipFeeOrderDetail = {
					channelItemSimpleModel: null,
					channelItemType: 'MEMBERSHIP_FEE',
					itemCode: addonItem.membershipFeePolicyModel.code,
					totalOrderCount: 1,
					existingCount: 0,
					addCount: 1,
					price: addonItem.membershipFeePolicyModel.price,
					finalPrice: addonItem.membershipFeeDiscountPrice,
					note: null,
					discountInfoModels: addonMembershipFeeDiscountInfoModels,
					status: 'USING',
					itemCancelInfoModel: null,
				};
				// to-be : addUserFeeOrderDetails
				let addonAddUserFeeOrderDetails = [];
				const addonAddUserFeeDiscountIdx = addonItem.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'ADD_USER',
				);
				let addonAddUserFeeDiscountInfoModels = [];
				if (addonAddUserFeeDiscountIdx > -1) {
					addonAddUserFeeDiscountInfoModels.push(addonItem.discountPolicyModels[addonAddUserFeeDiscountIdx]);
				}
				addonItem.addUserDiscountMatrix.forEach((data) => {
					addonAddUserFeeOrderDetails.push({
						channelItemSimpleModel: null,
						channelItemType: data.addUserIntervals.channelItemType,
						itemCode: data.code,
						totalOrderCount: data.discountCnt,
						existingCount: 0,
						addCount: data.discountCnt,
						price: data.price,
						finalPrice: data.totPrice,
						note: null,
						discountInfoModels: addonAddUserFeeDiscountInfoModels,
						status: 'USING',
						itemCancelInfoModel: null,
					});
				});
				//itemOrderDetails(옵션상품리스트)
				let addonItemOrderDetails = [];
				addonItem.optionItemSelected.forEach((data) => {
					addonItemOrderDetails.push({
						channelItemSimpleModel: data,
						channelItemType: data.itemType,
						itemCode: data.code,
						totalOrderCount: data.orderCount,
						existingCount: 0,
						addCount: 0,
						price: data.channelItemSalesSectionModel.price,
						finalPrice: data.finalPrice,
						note: data.name,
						discountInfoModels: [],
						status: 'USING',
						itemCancelInfoModel: null,
					});
				});

				let freeTrialOrderType = 'START_CHARGING';
				const addonChannelOrderInfoModel = {
					addonChannelModel: addonChannelModel,
					addonChannelBaseInfoModel: addonChannelBaseInfoModel,
					applyDate: startDate,
					endDate: endDate,
					defaultFeeOrderDetail: addonDefaultFeeOrderDetail,
					membershipFeeOrderDetail: addonMembershipFeeOrderDetail,
					addUserFeeOrderDetails: addonAddUserFeeOrderDetails,
					itemOrderDetails: addonItemOrderDetails,
					new: false,
					freeTrialOrderType: freeTrialOrderType,
				};

				addonChannelOrderInfoModels.push(addonChannelOrderInfoModel);
			});

			const totPrices = this.$store.getters.getSummuryPrices;
			const param = {
				id: null,
				createdAt: null,
				updatedAt: null,
				createdBy: null,
				updatedBy: null,
				customer: this.usingMembership.customer,
				membershipModel: this.usingMembership,
				baseInfoModel: baseInfoModel,
				addonChannelOrderInfoModels: addonChannelOrderInfoModels,
				partnerSimpleModel: this.usingMembership.partnerSimpleModel,
				userCnt: this.item.addonChannelModel.userCnt,
				amount: totPrices.totPayPrice,
				serviceType: serviceType,
				payType: payType,
				totalPrice: totPrices.finalPayPrice,
				paymentDate: null,
				new: true,
				issuedBill: this.payMethodInfo.issuedBill,
			};
			return param;
		},
		getNextPaymentDate(startDate) {
			// 사용기간
			let date = new Date(startDate);

			date.setMonth(date.getMonth() + 1);
			const year = date.getFullYear();
			const month = new String(date.getMonth() + 1);
			const day = new String(date.getDate());

			return year + '년 ' + month + '월 ' + day + '일 ';
		},
		closePopup() {
			this.enablePopup = false;
			this.saveFlag = false;
		},
		async apply() {
			//this.$ms.modal.show(Kiwoompay);
			await this.createOrder();

			if (this.payMethodInfo.payType == 'DEPOSIT') {
				//this.$router.push('/apply/service/step3');
				return;
			} else {
				this.enablePopup = true;

				//this.$router.push('/apply/service/step3');
				window.addEventListener(
					'message',
					(event) => {
						if (event.data === 'success') {
							alert('결제완료되었습니다.');
							this.enablePopup = false;
						} else if (event.data === 'close') {
							this.enablePopup = false;
						}
					},
					false,
				);
				return false;
			}
		},
		async setPayMethodInfo(payMethodInfo) {
			if (!payMethodInfo.infoConsent) {
				alert('결제 방법 안내 동의가 필요합니다.');
				this.saveFlag = false;
				return;
			}
			await this.$store.commit('setPayMethodInfo', payMethodInfo);
			this.payMethodInfo = this.$store.getters.getPayMethodInfo;
			this.payMethodInfo['amount'] = this.$store.getters.getSummuryPrices.finalPayPrice;
			console.log(this.payMethodInfo);
			localStorage.setItem('payMethodInfo', JSON.stringify(this.payMethodInfo));
			await this.apply();
		},
		saveAutoExtension() {
			console.log(this.item);
			this.enablePopup = true;
		},
		save() {
			this.saveFlag = true;
		},
		closeLayer() {
			this.$emit('closeLayer');
		},
		setBaseInfo(item, usePeriod, userCnt) {
			// 서비스 객체 기본 속성들 설정
			item['usePeriod'] = usePeriod;
			item['userCnt'] = userCnt;
			item['addUserCnt'] =
				item.userCnt - item.defaultFeePolicyModel.defaultUserNum > 0
					? item.userCnt - item.defaultFeePolicyModel.defaultUserNum
					: 0;
			return item;
		},
		setMembershipFeeDiscountMatrix(item) {
			// 설치비(도입비) 원가, 할인가 setting

			var discountPolicy = null;
			var discountPolicyIndex = -1;
			if (item.discountPolicyModels == undefined) {
				item['discountPolicyModels'] = [{ itemType: 'NONE', benefitType: 'NONE', num: 0 }];
				discountPolicy = item.discountPolicyModels[0];
			} else {
				discountPolicyIndex = item.discountPolicyModels.findIndex((obj) => obj.itemType == 'MEMBERSHIP_FEE');
				if (discountPolicyIndex > -1) {
					discountPolicy = item.discountPolicyModels[discountPolicyIndex];
				} else {
					discountPolicy = {
						itemType: 'NONE',
						benefitType: 'NONE',
						num: 0,
					};
				}
			}

			item['membershipFeeNormalPrice'] = item.membershipFeePolicyModel.price;
			item['membershipFeeDiscountPrice'] =
				parseInt(item.membershipFeePolicyModel.price) *
					(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) -
				(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0);

			// price Cutting
			item.membershipFeeNormalPrice = this.priceCutting(item.membershipFeeNormalPrice, 'F', 1);
			item.membershipFeeDiscountPrice = this.priceCutting(item.membershipFeeDiscountPrice, 'F', 1);
			item['membershipFeeDiscount'] = (item.membershipFeeNormalPrice - item.membershipFeeDiscountPrice) * -1;

			if (discountPolicyIndex > -1) {
				if (item.membershipFeeNormalPrice - item.membershipFeeDiscountPrice > 0) {
					item.discountPolicyModels[discountPolicyIndex]['discountAmount'] =
						(item.membershipFeeNormalPrice - item.membershipFeeDiscountPrice) * -1;
				}
			}
			return item;
		},
		setDefaultFeeDiscountMatrix(item) {
			// 기본료 원가, 할인가 setting
			var discountPolicy = null;
			var discountPolicyIndex = -1;
			if (item.discountPolicyModels == undefined) {
				item['discountPolicyModels'] = [{ itemType: 'NONE', benefitType: 'NONE', num: 0 }];
				discountPolicy = item.discountPolicyModels[0];
			} else {
				discountPolicyIndex = item.discountPolicyModels.findIndex((obj) => obj.itemType == 'DEFAULT_FEE');
				if (discountPolicyIndex > -1) {
					discountPolicy = item.discountPolicyModels[discountPolicyIndex];
				} else {
					discountPolicy = {
						itemType: 'NONE',
						benefitType: 'NONE',
						num: 0,
					};
				}
			}

			const payPeriod = parseFloat(parseInt(item.usePeriod.remainMonth) + parseInt(item.usePeriod.remainDay) / 30);
			item['defaultFeeTotNormalPrice'] = item.defaultFeePolicyModel.price * payPeriod;
			item['defaultFeeTotDiscountPrice'] =
				parseInt(item.defaultFeePolicyModel.price) *
					(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) *
					payPeriod -
				(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0);

			item.defaultFeeTotNormalPrice = this.priceCutting(item.defaultFeeTotNormalPrice, 'F', 1);
			item.defaultFeeTotDiscountPrice = this.priceCutting(item.defaultFeeTotDiscountPrice, 'F', 1);
			item['defaultFeeTotDiscount'] = (item.defaultFeeTotNormalPrice - item.defaultFeeTotDiscountPrice) * -1;

			if (discountPolicyIndex > -1) {
				item.discountPolicyModels[discountPolicyIndex]['discountAmount'] =
					-1 *
					(parseInt(item.defaultFeePolicyModel.price) *
						(discountPolicy.benefitType == 'RATE' ? discountPolicy.num / 100 : 0) *
						payPeriod +
						(discountPolicy.benefitType == 'AMOUNT' ? discountPolicy.num : 0));

				item.discountPolicyModels[discountPolicyIndex].discountAmount = this.priceCutting(
					item.discountPolicyModels[discountPolicyIndex].discountAmount,
					'F',
					1,
				);
			}
			return item;
		},
		setAddUserDiscountMatrix(item) {
			// 사용자추가 원가, 할인가 setting
			item['addUserDiscountMatrix'] = [];
			const defaultCnt = parseInt(item.defaultFeePolicyModel.defaultUserNum);
			var addUserCnt = parseInt(item.addUserCnt);
			// 행사(프로모션) 적용
			var discountPolicy = null;
			var discountPolicyIndex = -1;
			if (item.discountPolicyModels == undefined) {
				item['discountPolicyModels'] = [{ itemType: 'NONE', benefitType: 'NONE', num: 0 }];
				discountPolicy = item.discountPolicyModels[0];
			} else {
				const discountPolicyIndex = item.discountPolicyModels.findIndex((obj) => obj.itemType == 'ADD_USER');
				if (discountPolicyIndex > -1) {
					discountPolicy = item.discountPolicyModels[discountPolicyIndex];
					item.discountPolicyModels[discountPolicyIndex]['discountAmount'] = 0;
				} else {
					discountPolicy = {
						itemType: 'NONE',
						benefitType: 'NONE',
						num: 0,
					};
				}
			}
			const payPeriod = parseFloat(parseInt(item.usePeriod.remainMonth) + parseInt(item.usePeriod.remainDay) / 30);
			// 사용자 수 구간별 계산
			var addUserIntervals = item.addUserIntervalPolicyModel.addUserIntervals;
			var addUserNormalPrice = 0;
			var addUserDiscountSum = 0;
			var periodDiscountSum = 0;
			for (var idx in addUserIntervals) {
				var i = addUserIntervals[idx];
				if (defaultCnt < i.max && addUserCnt > 0) {
					const a = {
						addUserIntervals: addUserIntervals[idx],
						interval: i.min + '~' + i.max,
						price: i.price,
						code: i.code,
						discountCnt: 0,
						discountInfo:
							discountPolicy == null
								? ''
								: '<span>프로모션할인 <span class="em">' +
								  discountPolicy.num +
								  (discountPolicy.benefitType == 'AMOUNT' ? '원' : '%') +
								  '</span></span><br>',
						totPrice: 0,
						discountTotPrice: 0,
						originPrice: 0,
						discountPrice: 0,
						periodDiscountPrice: 0,
					};
					if (addUserCnt <= i.max - i.min) {
						a.discountCnt = addUserCnt;
						addUserCnt -= addUserCnt;
					} else if (defaultCnt > i.min) {
						a.discountCnt = i.max - defaultCnt;
						addUserCnt -= i.max - defaultCnt;
					} else {
						if (i.min == 0) {
							a.discountCnt = i.max - i.min;
						} else {
							a.discountCnt = i.max - i.min + 1;
						}
						addUserCnt -= a.discountCnt;
					}
					a.originPrice = a.discountCnt * i.price * payPeriod;
					if (discountPolicy !== null && discountPolicy.benefitType == 'RATE' && discountPolicy.num > 0) {
						a.totPrice =
							a.discountCnt *
							i.price *
							(discountPolicy.benefitType == 'RATE' ? (100 - discountPolicy.num) / 100 : 1) *
							payPeriod;
						a.discountPrice = a.originPrice - a.totPrice;
					} else if (discountPolicy !== null && discountPolicy.benefitType == 'AMOUNT' && discountPolicy.num > 0) {
						if (a.totPrice < discountPolicy.num) {
							discountPolicy.num -= a.totPrice;
							a.discountPrice = a.totPrice;
						} else {
							a.totPrice -= discountPolicy.num;
							a.discountPrice = discountPolicy.num;
							discountPolicy.num = 0;
						}
					} else {
						a.totPrice = a.originPrice;
					}

					a.originPrice = this.priceCutting(a.originPrice, 'F', 1);
					a.totPrice = this.priceCutting(a.totPrice, 'F', 1);
					a.discountPrice = a.originPrice - a.totPrice;

					a.discountTotPrice = a.discountCnt * item.addUserIntervalPolicyModel.price * payPeriod - a.totPrice;
					a.discountTotPrice = this.priceCutting(a.discountTotPrice, 'F', 1) * -1;

					item.addUserDiscountMatrix.push(a);
				}
			}
			item.addUserDiscountMatrix.forEach((row) => {
				row['totPriceReal'] = row.totPrice;
			});

			var disTot = 0;
			var sumTot = 0;
			var sumOriginTot = 0;
			var sumDiscount = 0;
			var sumPeriodDiscount = 0;

			for (var index in item.addUserDiscountMatrix) {
				var obj = item.addUserDiscountMatrix[index];
				disTot += obj.discountTotPrice;
				sumTot += obj.totPrice;
				sumOriginTot += obj.originPrice;
				sumDiscount += obj.discountPrice;
				sumPeriodDiscount += obj.periodDiscountPrice;
			}
			item['addUserTotNormalPrice'] = sumOriginTot;
			item['addUserTotDiscountPrice'] = sumTot;
			item['addUserTotDiscount'] = disTot;

			if (sumDiscount > 0 && discountPolicyIndex > -1) {
				item.discountPolicyModels[discountPolicyIndex]['discountAmount'] = -1 * sumDiscount;
			}
			if (sumPeriodDiscount > 0) {
				item.periodPolicyModel.discountAmount.push(-1 * sumPeriodDiscount);
			}

			return item;
		},
		async setDiscountSum(item) {
			if (item.periodPolicyModel == undefined || item.periodPolicyModel == null) {
				return;
			}

			if (
				item.periodPolicyModel.discountAmount !== undefined &&
				item.periodPolicyModel.discountAmount !== null &&
				item.periodPolicyModel.discountAmount.length > 0
			) {
				var discountAmountTot = 0;

				item.periodPolicyModel.discountAmount.forEach((val) => {
					discountAmountTot += parseInt(val);
				});

				item.periodPolicyModel['discountAmountTot'] = discountAmountTot;
			}
			return item;
		},
		setInfo_ACO(item, remainDay, orderCount) {
			item['orderCount'] = orderCount;

			const payPeriod = parseFloat(parseInt(remainDay.remainMonth) + parseInt(remainDay.remainDay) / 30);
			if (item.channelItemSalesSectionModel.billingType == 'MONTHLY') {
				// 월과금
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price) * payPeriod * orderCount;
			} else if (item.channelItemSalesSectionModel.billingType == 'ONCE') {
				// 1회성
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price) * orderCount;
			} else if (item.billingType == 'ESTIMATE') {
				//견적가
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price);
			} else {
				item['finalPrice'] = parseInt(item.channelItemSalesSectionModel.price);
			}

			item.finalPrice = this.priceCutting(item.finalPrice, 'F', 1);
			item['finalPriceReal'] = item.finalPrice;
			return item;
		},
	},
};
</script>

<style>
#popupLayer {
	z-index: 9998;
}

iframe html {
	margin-top: 50px !important;
}
</style>
