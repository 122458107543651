<template>
	<div v-if="usingMembership !== undefined && usingMembership !== null">
		<div class="container">
			<h2>기본 서비스 정보</h2>
			<div class="com_box_membership box_home_join">
				<h1>
					{{ usingMembership.baseChannelInfoModel.baseChannelModel.name
					}}<!--<span class="txt">{{
						$constants.serviceTypeDisplay[
							usingMembership.baseChannelInfoModel.baseChannelModel
								.serviceCategoryModel.serviceType
						]
					}}</span>-->
				</h1>

				<!--멤버십 정보-->
				<div class="wrap_data_membership">
					<div class="default">
						<ul>
							<li v-if="usingMembership.baseChannelInfoModel.totalUserCount > 0">
								<span class="com_tag">
									<span class="txt">
										사용자
										{{ usingMembership.baseChannelInfoModel.totalUserCount }}인
									</span>
								</span>
								<span class="txt" v-if="usingMembership.baseDefaultUserCnt > 0">
									기본 {{ usingMembership.baseDefaultUserCnt }}명
								</span>
								<span class="txt" v-if="usingMembership.baseAddUserCnt > 0">
									+ 추가 {{ usingMembership.baseAddUserCnt }}명
								</span>
							</li>
							<li v-if="usingMembership.baseChannelInfoModel.totalQuota > 0">
								<span class="com_tag">
									<span class="txt"> {{ usingMembership.baseChannelInfoModel.totalQuota }}GB </span>
								</span>
								<span class="txt" v-if="usingMembership.baseDefaultQuota > 0">
									기본 {{ usingMembership.baseDefaultQuota }}GB
								</span>
								<span class="txt" v-if="usingMembership.baseAddQuota > 0">
									+ 추가 {{ usingMembership.baseAddQuota }}GB
								</span>
								<!--<span class="txt">
									+ 멤버 2GB + 추가 100GB
								</span>-->
							</li>
							<li v-if="usingMembership.membershipChargeType !== 'FREE'">
								<span class="com_tag">
									<span class="txt">{{ totUsePeriod }} </span>
								</span>
								<span class="txt" v-if="remainDay.remainMonth > 0 || remainDay.remainDay > 0">
									( 잔여 :
									<template v-if="remainDay.remainMonth > 0"> {{ remainDay.remainMonth }}개월 </template>
									<template v-if="remainDay.remainDay > 0"> {{ remainDay.remainDay }}일 </template>)
								</span>
							</li>
						</ul>
					</div>
					<div class="divide"></div>
					<div class="option">
						<strong>옵션</strong>
						<ul v-if="usingMembership.baseChannelInfoModel.usingChannelItemModels.length > 0">
							<template v-for="(item, index) in usingMembership.baseChannelInfoModel.usingChannelItemModels">
								<template v-if="item.visible && item.channelItemType == 'VIRTUAL_DOMAIN'">
									<li :key="'virtualDoamin_' + index" v-for="(row, index) in getVirtualDomains()">
										<span class="tit">가상도메인{{ index + 1 }}</span>
										<span class="data">{{ row }}</span>
									</li>
								</template>
								<li :key="'etc_' + index" v-if="item.visible && item.channelItemType !== 'VIRTUAL_DOMAIN'">
									<span class="tit">
										{{ item.channelItemModel.displaySectionModel.displayName }}
									</span>
									<span class="data">{{ item.usingCount }}개</span>
								</li>
							</template>
						</ul>
						<span
							class="txt"
							v-if="usingMembership.baseChannelInfoModel.usingChannelItemModels.length > 5"
							v-on:click="
								toggleOptionFold(
									usingMembership.baseChannelInfoModel.usingChannelItemModels,
									usingMembership.baseChannelInfoModel,
								)
							"
						>
							<span
								class="ic_set"
								:class="usingMembership.baseChannelInfoModel.foldFlag ? 'ic_arrow_b' : 'ic_arrow_t'"
							></span>
							<a>{{ toggleText }}</a>
						</span>

						<ul v-if="usingMembership.baseChannelInfoModel.usingChannelItemModels.length == 0">
							<li>
								<span class="data">사용중인 옵션 상품이 없습니다.</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!--//container-->

		<!-- 부가서비스  -->
		<using-addon-status :usingMembership="usingMembership"></using-addon-status>

		<!-- 부가서비스 무료체험 -->
		<using-free-trial-addon></using-free-trial-addon>

		<!--container v-if="freeTrialAddonList.length > 0"-->

		<!-- 차트 -->
		<!--<MembershipChart :membershipId="usingMembership.id" :useAccountAddon="isUsingAccountAddon()"></MembershipChart>-->
		<!-- 유료전환 레이어 -->
		<converting-layer
			v-if="convertingLayerFlag"
			:usingMembership="usingMembership"
			:item="convertingServiceData"
			:endDate="usingMembership.baseChannelInfoModel.endDate"
			v-on:closeLayer="closeLayer"
		></converting-layer>
	</div>
</template>

<script>
import ConvertingLayer from '@/components/apply/user/ConvertingLayer';
import MembershipChart from './MembershipChart';
import Chart from 'chart.js';
import cloneDeep from 'lodash/cloneDeep';
import UsingAddonStatus from '@/components/user/UsingAddonStatus.vue';
import UsingFreeTrialAddon from '@/components/user/UsingFreeTrialAddon.vue';

export default {
	components: {
		ConvertingLayer,
		// MembershipChart,
		UsingAddonStatus,
		UsingFreeTrialAddon,
	},
	data() {
		return {
			convertingServiceData: null,
			convertingLayerFlag: false,
			moveType: '',
			usingMembership: null,
			freeTrialAddonList: [],
			remainDay: {},
			today: this.$moment().format('YYYY-MM-DD'),
			toggleText: 'more',
		};
	},
	computed: {
		totUsePeriod() {
			const fromDate = this.$moment(this.usingMembership.membershipBaseInfoModel.startDate);
			const toDate = this.$moment(this.usingMembership.membershipBaseInfoModel.expirationDate).format('YYYY-MM-DD');
			const today = this.$moment();
			if (today.isAfter(fromDate)) {
				return today.format('YYYY-MM-DD') + '~' + toDate;
			} else {
				return fromDate.format('YYYY-MM-DD') + '~' + toDate;
			}
		},
	},
	async created() {
		let data;
		try {
			data = await this.$store.dispatch('GETMEMBERSHIP');
			if (!data) {
				const token = sessionStorage.getItem('userToken');
				if (token) {
					const result = await this.$store.dispatch('USER_SSO', token);
					data = await this.$store.dispatch('GETMEMBERSHIP');
				}
			}
		} catch (e) {
			const token = sessionStorage.getItem('userToken');
			if (token) {
				const result = await this.$store.dispatch('USER_SSO', token);
				data = await this.$store.dispatch('GETMEMBERSHIP');
			}
		}

		// 사용자 수 기본, 추가 separation
		// baseChannel, addonChannel 옵션 설정
		data.baseChannelInfoModel['foldFlag'] = true;
		await this.initOptionList(data.baseChannelInfoModel.usingChannelItemModels);
		data.addonChannelInfoModels.reduce((prevous, addonChannelInfoModel) => {
			return prevous.then(async () => {
				addonChannelInfoModel['foldFlag'] = true;
				await this.initOptionList(addonChannelInfoModel.usingChannelItemModels);
			});
		}, Promise.resolve());

		let baseDefaultUserCnt = parseInt(data.baseChannelInfoModel.baseChannelModel.defaultFeePolicyModel.defaultUserNum);

		data['baseDefaultUserCnt'] = baseDefaultUserCnt; // 기본서비스 기본 사용자수
		data['baseAddUserCnt'] = parseInt(data.baseChannelInfoModel.totalUserCount) - baseDefaultUserCnt; // 기본서비스 추가 사용자수

		// 용량 기본, 추가 separation
		let baseDefaultQuota = parseInt(data.baseChannelInfoModel.baseChannelModel.defaultFeePolicyModel.defaultVolume);
		data['baseDefaultQuota'] = baseDefaultQuota; // 기본서비스 기본 용량
		data['baseAddQuota'] = parseInt(data.baseChannelInfoModel.totalQuota) - baseDefaultQuota; // 기본서비스 기본 용량

		this.usingMembership = data;

		this.remainDay = await this.getRemainDays(); // 남은 사용일 수
		// 무료체험중인 부가서비스 조회.
		await this.setFreeTrialAddonList(this.usingMembership.baseChannelInfoModel.baseChannelModel.code);
	},
	methods: {
		isUsingAccountAddon() {
			const accountAddon = this.usingMembership.addonChannelInfoModels.filter(
				(o) => o.addonChannelModel.name.indexOf('경리회계') > -1,
			);
			if (accountAddon.length > 0) return true;
			return false;
		},
		shorteningWords(str) {
			if (str.length > 9) {
				return str.substr(0, 9) + '...';
			}
			return str;
		},

		async getRemainDays() {
			// api 호출

			const today = new Date();
			const year = new String(today.getFullYear());
			let month = new String(today.getMonth() + 1);
			let day = new String(today.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			const todayStr = year + '-' + month + '-' + day;
			const { data } = await this.$store.dispatch('GETREMAINDAYS', {
				membershipId: this.usingMembership.id,
				startDate: todayStr,
			});

			const remainMonth = data.remainMonth;
			const remainDay = data.remainDay;

			return {
				remainMonth,
				remainDay,
			};
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
			this.toggleText = item.foldFlag ? 'more' : 'fold';
		},
		goPage(path) {
			this.$router.push(path);
		},
		ConvertingPaid(item) {
			item.autoExtension = this.usingMembership.autoExtension;
			if (item.autoExtension) {
				item.usePeriod = 1;
			} else {
				item.usePeriod = this.remainDay;
			}
			this.convertingServiceData = item;
			this.convertingLayerFlag = true;
		},
		closeLayer() {
			this.convertingLayerFlag = false;
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		async setFreeTrialAddonList(param) {
			// 해당 기본서비스에 귀속된, 무료체험 정보들 setting
			var freeTrialAddonList = [];
			try {
				var { data } = await this.$store.dispatch('GET_ADDONCHANNELLIST', param); // 부가서비스 리스트
				if (data == undefined || data == null || Object.keys(data).length == 0) {
					return;
				}
				freeTrialAddonList = data.filter((obj) => obj.freeTrialPolicyModel.usePolicy);

				// 현재 체험중인 무료체험 제외

				const usingFreeTrial = this.usingMembership.addonChannelInfoModels.filter(
					(obj) => obj.addonChannelModel.freeTrialPolicyModel.usePolicy,
				);
				await usingFreeTrial.reduce((previous, obj) => {
					return previous.then(async () => {
						let length = freeTrialAddonList.length;
						for (let i = 0; i < length; i++) {
							if (freeTrialAddonList[i].code == obj.addonChannelModel.code) {
								freeTrialAddonList.splice(i, 1);
								i--;
								length = freeTrialAddonList.length;
							}
						}
					});
				}, Promise.resolve());

				await freeTrialAddonList.reduce((previous, current) => {
					return previous.then(async () => {
						current['foldFlag'] = true;
						await this.initOptionList(current.optionMatrixModels);
					});
				}, Promise.resolve());

				this.freeTrialAddonList = freeTrialAddonList;
			} catch (error) {
				console.log(error);
			}
		},
		async initOptionList(items) {
			// 5개까지만 보여주기
			if (items) {
				for (let idx = 0; idx < items.length; idx++) {
					if (idx < 5) {
						items[idx]['visible'] = true;
					} else {
						items[idx]['visible'] = false;
					}
				}
			}
		},
		getVirtualDomains() {
			return this.usingMembership.representativeGroupware.virtualDomain;
		},
		StringToArray(text) {
			if (!text) {
				return null;
			}
			let arr = text.split(',');
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].length == 0) {
					arr.splice(i, 1);
					i--;
				}
			}

			return arr;
		},

		textCenter(val) {
			Chart.pluginService.register({
				beforeDraw: function (chart) {
					if (chart.id != 0) return;
					var width = chart.chart.width;
					var height = chart.chart.height;
					var ctx = chart.chart.ctx;

					ctx.restore();
					var fontSize = (height / 200).toFixed(2);
					ctx.font = fontSize + 'em sans-serif';
					ctx.textBaseline = 'middle';

					var text = val;
					var textX = Math.round((width - ctx.measureText(text).width) / 2);
					var textY = height / 2;

					ctx.fillText(text, textX, textY);
					ctx.save();
				},
			});

			Chart.plugins.unregister(this.chartdata);
		},
	},
};
</script>

<style scoped></style>
