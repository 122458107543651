<template>
	<div>
		<component :is="chartType" :chartData="chartData" :options="options"></component>
	</div>
</template>
<script>
export default {
	props: ['chartType', 'chartData', 'options'],
	components: {
		BarChart: () => import('@/components/chart/BarChart'),
		LineChart: () => import('@/components/chart/LineChart'),
		PieChart: () => import('@/components/chart/PieChart'),
		DoughnutChart: () => import('@/components/chart/DoughnutChart'),
	},
	watch: {
		chartData: {
			handler: function (val, oldVal) {},
			deep: true,
		},
	},
};
</script>
<style></style>
