<template>
	<div class="wrap_col">
		<h2>{{ title }}</h2>
		<div class="wrap_content">
			<div class="graph">
				<div id="chartDiv">
					<DomsChart :chartType="'DoughnutChart'" :chartData="chartData" :options="options"> </DomsChart>
				</div>
			</div>
			<div class="data_cicle">
				<span class="data txt" v-if="!labelData.unlimited">
					<strong>{{ labelData.total | currency }}</strong>
					{{ unit }}
				</span>
				<span v-else>
					<strong>무제한</strong>
				</span>
				<span class="tit txt">전체 {{ unitName }}</span>
			</div>
		</div>
		<table class="com_tb_metadata">
			<tbody>
				<tr>
					<td class="tit"><span class="txt">사용중 </span></td>
					<td class="data">
						<span class="txt">
							<strong>{{ labelData.using | currency }}</strong>
							{{ unit }}
						</span>
					</td>
				</tr>
				<tr>
					<td class="tit"><span class="txt">잔여 </span></td>
					<td class="data">
						<span class="txt" v-if="!labelData.unlimited">
							<strong>{{ labelData.remain | currency }}</strong>
							{{ unit }}
						</span>
						<span v-else>
							<strong>무제한</strong>
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import DomsChart from '@/components/chart/DomsChart';
export default {
	components: {
		DomsChart,
	},
	props: ['info'],
	data() {
		return {
			title: this.info.title,
			unitName: this.info.unitName,
			unit: this.info.unit,
			labelData: {
				total: this.info.total,
				using: this.info.using,
				remain: this.info.remain,
				unlimited: this.info.total == 0,
			},
			chartData: {
				labels: ['사용중', '잔여'],
				datasets: [
					{
						backgroundColor: [this.info.usingColor, this.info.remainColor],
						data: [this.info.using, this.info.total == 0 ? '무제한' : this.info.remain],
					},
				],
			},
			options: {
				responsive: true,
				animation: {
					animateRotate: true,
				},
				legend: {
					display: false,
				},
			},
		};
	},
};
</script>

<style scoped>
#chartDiv {
	width: 230px !important;
	height: 230px !important;
}
.data_cicle {
	margin: -21px 0 0 -95px !important;
}
.com_tb_metadata {
	margin-top: 20px !important;
}
</style>
