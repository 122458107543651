<template>
	<div class="container" v-if="false">
		<h2>무료 체험 정보</h2>

		<!--부가서비스 정보 : type 3 : 무료체험 사용중-->
		<div
			v-for="(item, index) in freeTrialAddonList"
			class="com_card_normal card_addon card_addon_home"
			:key="item.code + 'virtualDomain' + index"
		>
			<div class="wrap_row_card">
				<span class="ic_svc ic_accounting"></span>
				<span class="tit">{{ item.name }}</span>
				<span class="com_tag_badge">
					<span class="txt"> {{ item.freeTrialPolicyModel.usagePeriod }}개월 무료 체험 </span>
				</span>
				<button
					class="mono minor"
					v-on:click="confirmPurchaseAddon('freeTrial')"
					v-if="usingMembership.partnerSimpleModel.salesType !== 'RESELLER' && usingMembership.enableAccessStore"
				>
					<span class="txt">무료체험 신청</span>
				</button>
			</div>
			<div class="wrap_data_membership">
				<div class="default">
					<ul>
						<li v-if="item.defaultFeePolicyModel.defaultUserNum > 0">
							<span class="com_tag">
								<span class="txt">
									기본 사용자
									{{ item.defaultFeePolicyModel.defaultUserNum }}인
								</span>
							</span>
						</li>
						<li v-if="item.defaultFeePolicyModel.defaultVolum > 0">
							<span class="com_tag">
								<span class="txt">
									기본 용량
									{{ item.defaultFeePolicyModel.defaultVolume }}GB
								</span>
							</span>
						</li>
					</ul>
				</div>
				<div class="divide"><div class="line"></div></div>
				<div class="option">
					<strong>선택 가능 옵션</strong>
					<ul>
						<template v-for="(row, index) in item.optionMatrixModels">
							<li v-if="row.visible" :key="index">
								<span class="tit">
									<span
										v-if="addonChannelIconClass(data.channelItemModel.name, true)"
										class="ic_svc"
										:class="addonChannelIconClass(data.channelItemModel.name, true)"
									></span
									>{{ row.channelItemSimpleModel.name }}</span
								>
								<span class="data"> {{ row.channelItemSimpleModel.price | currency }}원 / 1인 </span>
							</li>
						</template>
					</ul>
					<span
						class="txt"
						v-if="item.optionMatrixModels.length > 5"
						v-on:click="toggleOptionFold(item.optionMatrixModels, item)"
					>
						<span class="ic_set ic_arrow_b" v-if="item.foldFlag"></span>
						<a v-if="item.foldFlag">more</a>
						<span class="ic_set ic_arrow_t" v-if="!item.foldFlag"></span>
						<a v-if="!item.foldFlag">fold</a>
					</span>

					<ul v-if="item.optionMatrixModels.length == 0">
						<span class="tit">제공하는 옵션 상품이 없습니다.</span>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		freeTrialAddonList: Array,
	},
	computed: {},
	methods: {
		addonChannelIconClass(addonChannelName, isOption) {
			if (addonChannelName.indexOf(this.$constants.ACCOUNTING) > -1) {
				return isOption ? 'ic_accounting_s' : 'ic_accounting';
			} else if (addonChannelName === this.$constants.BIZPLAY) {
				return 'ic_bizplay';
			} else if (addonChannelName === this.$constants.TMSE_S) {
				return 'ic_tmse_s';
			} else if (addonChannelName === this.$constants.TMSE_E) {
				return 'ic_tmse_e';
			} else if (addonChannelName === this.$constants.TMSE_SHARE) {
				return 'ic_tmse_share';
			} else if (addonChannelName.indexOf(this.$constants.ESS) > -1) {
				return 'ic_ess_s';
			}
			return undefined;
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
		},
		confirmPurchaseAddon(moveType) {
			const title = moveType == 'freeTrial' ? '무료체험 신청' : '부가서비스 구매';
			const content = moveType == 'freeTrial' ? '무료체험 신청을' : '부가서비스 구매를';

			this.$ms.dialog.confirm(
				title,
				content + ' 위해 멤버십 추가/연장 페이지로 이동합니다.',
				() => {
					this.goPage('/order/addExtension');
				},
				() => {},
			);
		},
	},
};
</script>

<style></style>
