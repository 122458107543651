<template>
	<Fragment>
		<div class="container wrap_graph">
			<div class="layout_col2">
				<template v-for="data in daouofficeInfo">
					<ExternalUsingStatusChart :info="data" :key="data.title"></ExternalUsingStatusChart>
				</template>
			</div>
		</div>
		<!-- 경리회계 옵션 차트 -->
		<div class="container wrap_graph account" v-show="useAccountAddon && !isTotalZero">
			<div class="layout_col2">
				<template v-for="data in accountChartData">
					<ExternalUsingStatusChart :info="data" :key="data.title" v-if="data.total != 0"></ExternalUsingStatusChart>
				</template>
			</div>
		</div>
	</Fragment>
</template>

<script>
import ExternalUsingStatusChart from '@/components/chart/StatusDoughnutChart';
import { Fragment } from 'vue-fragment';
export default {
	props: ['membershipId', 'useAccountAddon'],
	components: {
		ExternalUsingStatusChart,
		Fragment,
	},
	data() {
		return {
			daouofficeInfo: [],
			accountChartData: [],
		};
	},
	computed: {
		isTotalZero() {
			return this.accountChartData.length === this.accountChartData.filter((chart) => chart.total === 0).length;
		},
	},
	async created() {
		//사용량 차트
		this.drawChart(this.membershipId);
	},
	methods: {
		async drawChart(membershipId) {
			//사용자수, 사용량 차트
			await this.drawCompanyInfo(membershipId);
			//경리회계 부가서비스 옵션 관련 차트
			//Todo. 계정관리 사용량 정보 리턴값 맞으면 주석 해제해야함.
			if (this.useAccountAddon) {
				await this.drawAccountChart(membershipId);
			}
		},
		async drawCompanyInfo(membershipId) {
			// DO 데이터와 경리회계 사용량 응답데이터가 달라서 따로 구현
			const data = await this.getCompanyUsage(membershipId);
			let allQuota = parseInt(data.purchaseAccountQuota) + parseInt(data.purchaseCompanyQuota);
			let usingQuota = parseInt(data.usedAccountQuota) + parseInt(data.usedCompanyQuota);

			const usageInfo = {
				title: '사용량',
				unitName: '용량',
				unit: 'GB',
				total: allQuota,
				using: usingQuota,
				remain: allQuota - usingQuota,
				usingColor: '#44DADD',
				remainColor: '#F2F2F2',
			};

			const userInfo = {
				title: '사용자 수',
				unitName: '사용자',
				unit: '명',
				total: parseInt(data.purchaseUserCount),
				using: parseInt(data.usedUserCount),
				remain: parseInt(data.purchaseUserCount) - parseInt(data.usedUserCount),
				usingColor: '#44DADD',
				remainColor: '#F2F2F2',
			};
			this.daouofficeInfo.push(usageInfo, userInfo);
		},
		async drawAccountChart(membershipId) {
			try {
				const { data } = await this.getAccountAddonUsage(membershipId);

				//법인카드 차트
				const cardInfo = this.generateChartData(data.optionUsages, 'ADD_ON_CREDIT_CARD');
				//전자 세금계산서 차트
				const taxBillInfo = this.generateChartData(data.optionUsages, 'ADD_ON_TAX_BILL');
				//유료계좌 수
				const backAccount = this.generateChartData(data.optionUsages, 'ADD_ON_BANK_ACCOUNT');
				//유료 개인카드 스크래핑 수
				const personalCard = this.generateChartData(data.optionUsages, 'ADD_ON_PERSONAL_CARD');
				this.accountChartData.push(cardInfo, taxBillInfo, backAccount, personalCard);
			} catch (e) {
				console.error('경리회계 옵션상품 사용현황 호출 오류');
			}
		},
		generateChartData(data, chartType) {
			const options = {
				ADD_ON_CREDIT_CARD: {
					title: '법인카드 사용수',
					unitName: '카드',
					unit: '개',
					usingColor: '#44DADD',
					remainColor: '#F2F2F2',
				},
				ADD_ON_TAX_BILL: {
					title: '세금계산서 발행 수',
					unitName: '수',
					unit: '개',
					usingColor: '#44DADD',
					remainColor: '#F2F2F2',
				},
				ADD_ON_BANK_ACCOUNT: {
					title: '유료계좌 수',
					unitName: '수',
					unit: '개',
					usingColor: '#44DADD',
					remainColor: '#F2F2F2',
				},
				ADD_ON_PERSONAL_CARD: {
					title: '실시간 개인카드 스크래핑 수',
					unitName: '수',
					unit: '개',
					usingColor: '#44DADD',
					remainColor: '#F2F2F2',
				},
			};

			//뽑아낼 키 선정
			let filterArray = [];
			const itemType = this.identity('itemType');
			const free = this.identity('free');
			filterArray.push(itemType(chartType));

			//계좌는 유료만 노출한다.
			if (chartType == 'ADD_ON_BANK_ACCOUNT') {
				filterArray.push(free(false));
			}

			//차트종류별로 추출
			const info = this.getAccountItemInfo(data, ...filterArray);
			return {
				total: info.finalCount,
				using: info.usageCount,
				remain: info.remainCount,
				...options[chartType],
			};
		},
		getAccountAddonUsage(membershipId) {
			//경리회계 옵션상품 사용량 조회
			return this.$store.dispatch('GET_ACCOUNT_USAGE', membershipId);
		},
		getCompanyUsage(membershipId) {
			// 사용중 용량, 사용자 수 조회
			return this.$store.dispatch('GETCOMPANYUSAGE', membershipId);
		},

		identity(field) {
			return (value) => (dataset) => dataset[field] === value;
		},
		getAccountItemInfo(datasets, ...checks) {
			return datasets
				.filter((dataset) => checks.every((check) => check(dataset)))
				.reduce(
					(a, b) => {
						return {
							finalCount: parseInt(a.finalCount) + parseInt(b.finalCount),
							usageCount: parseInt(a.usageCount) + parseInt(b.usageCount),
							remainCount:
								parseInt(a.finalCount) + parseInt(b.finalCount) - (parseInt(a.usageCount) + parseInt(b.usageCount)),
						};
					},
					{ finalCount: 0, usageCount: 0, remainCount: 0 },
				);
		},
	},
};
</script>

<style></style>
